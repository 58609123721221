import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "secondary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    fill: "outline",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openNewBookingModal()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: $setup.add
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString($setup.t('newBooking')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('bookings')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_toolbar, { mode: "md" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_segment, {
                modelValue: $setup.bookingStatusFilter,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.bookingStatusFilter) = $event)),
                scrollable: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment_button, { value: "upcoming" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('upcoming')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, { value: "completed" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('completed')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, { value: "cancelled" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('cancelled')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (index) => {
                        return _createVNode(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_skeleton_text)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"80%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"60%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"30%"}
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      }), 64))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bookings, (booking) => {
                        return _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
                          key: booking.id,
                          "router-link": {
            name: 'BookingDetailPage',
            params: { id: booking.id }
          },
                          button: "",
                          detail: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, _toDisplayString(booking.date) + " " + _toDisplayString(booking.startTime), 1),
                                _createElementVNode("p", null, _toDisplayString(booking.duration) + " " + _toDisplayString($setup.t('minutes')), 1),
                                _createVNode(_component_ion_badge, {
                                  color: $setup.getBookingStatusColor(booking.status)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString($setup.t(booking.status)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["router-link"])), [
                          [_vShow, $setup.needShowBooking(booking, $setup.bookingStatusFilter)]
                        ])
                      }), 128))
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}