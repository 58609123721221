
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton,
        IonRefresher, IonRefresherContent, IonSkeletonText,
        IonSegment, IonSegmentButton, IonBadge,
        modalController, } from '@ionic/vue';
        
import BookingModal from '@/components/BookingModal.vue';

// services
import BookingService from '@/services/BookingService';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import moment from 'moment';

export default {
  name: 'BookingPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton,
                IonRefresher, IonRefresherContent, IonSkeletonText,
                IonSegment, IonSegmentButton, IonBadge, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { getBookingStatusColor, formatDate } = utils();

    // 1. declare state variables (ref to make them reactive)
    const bookingStatusFilter = ref('upcoming');
    const loading = computed(() => store.state.loadingBookings);
    const bookings = computed(() => store.state.bookings);

    const needShowBooking = (booking: any, statusFilter: any) => {
      if (statusFilter == 'upcoming') return moment(booking.date).isSameOrAfter(moment(), 'date');
      return booking.status == statusFilter;
    }

    const fetchUserBookings = () => {
      store.state.loadingBookings = true;
      BookingService.getUserBookings().then(res => {
        store.commit('receiveBookings', res);
        store.state.loadingBookings = false;
      });
    };

    const openNewBookingModal = async () => {
      const modal = await modalController.create({
        component: BookingModal,
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data && data.newBookingCreated) {
          fetchUserBookings();
        }
      })
      return modal.present();
    };

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      add,

      // methods
      formatDate, openNewBookingModal, needShowBooking, getBookingStatusColor,

      // variables
      loading, bookings, bookingStatusFilter,
    }
  }
}
